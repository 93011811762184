import { useStaticQuery, graphql } from "gatsby"
import { useGetPropertyQuery as useGetPropertyQueryTokko} from "../redux/middlewareTokkoApi/properties"
import { useGetPropertyQuery as useGetPropertyQueryMediacore } from "../redux/mediacore/properties"

function useQueryProperty(id) {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
        env {
          CLIENTID
        }
      }
    }
  `)

  const {
    data: allPropertyData,
    isLoading,
    isError,
    isFetching
  } = realEstate.keys.tokko //EDIT
    ? useGetPropertyQueryTokko({"API_KEY":realEstate.keys.tokko,"ID":id})
    : useGetPropertyQueryMediacore({
        CLIENT_ID: realEstate.env.CLIENTID,
        PROP_ID: id
      })


  return { allPropertyData, isLoading, isError, isFetching }
}

export default useQueryProperty
